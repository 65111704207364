import Navigation from "./Navigation";

const Header = () => {
    return(
        <div className="headerCon">
            {/* <a className="headerLogo" href="#">Salon Naja</a> */}
            <Navigation />
        </div>
    )
}

export default Header;